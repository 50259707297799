import { Col, DatePicker, Form, Input, Radio, Row, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import moment from 'moment';
import { milestoneTypesOptions } from '@iso/lib/constants/milestoneTypes';
import RadioButtonWrapper from '@iso/assets/styles/antRadio.styles';
import { useWatch } from 'antd/lib/form/Form';

export default function MilestoneForm({
  isDate = false,
  prefixFields,
  suffixFields,
  colSpan = 24,
  form,
  isCreate,
  ...restProps
}) {
  const startDate = useWatch('startDate', form);

  const handleStartDateChange = (date) => {
    form.setFieldValue('startDate', date);
  };

  const disabledStartDate = (current) => {
    // Can not select days before today
    return current && current < moment().subtract(1, 'days');
  };
  const disabledEndDate = (current) => {
    // Can not select days before the selected start date
    return !startDate || (current && current <= moment(startDate));
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form form={form} {...restProps}>
      <Row gutter={24}>
        <Col span={colSpan}>
          <RadioButtonWrapper>
            <Form.Item name="milestoneType" label="">
              <Radio.Group>
                {Object.values(milestoneTypesOptions).map((type) => (
                  <Radio key={type.value} value={type.value}>
                    {type.title}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </RadioButtonWrapper>
        </Col>
      </Row>
      {prefixFields}
      <Row gutter={24}>
        <Col span={colSpan}>
          <Form.Item
            label="Milestone Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the Milestone Name',
              },
              {
                max: 50,
                message: 'Milestone Name cannot exceed 50 characters',
              },
            ]}
          >
            <Input placeholder="Enter Milestone Name..." allowClear />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter description',
              },
            ]}
          >
            <TextArea
              type="text"
              placeholder="Write description here..."
              autoSize={{ minRows: 3, maxRows: 5 }}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>

      {isDate && (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Start date"
              name="startDate"
              dependencies={['endDate']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      moment(value).isSameOrAfter(getFieldValue('startDate'))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Start Date should be before End Date'),
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                allowClear
                style={{ width: '100%' }}
                placeholder="Enter start date"
                format="MMM DD, YYYY"
                disabledDate={disabledStartDate}
                onChange={handleStartDateChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End date"
              name="endDate"
              dependencies={['startDate']}
              rules={[
                ({ getFieldValue }) => ({
                  required: getFieldValue('startDate') ? true : false,
                  message: 'Please enter the end date',
                }),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      moment(value).isSameOrAfter(getFieldValue('startDate'))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('End Date should be after Start Date'),
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                allowClear
                style={{ width: '100%' }}
                placeholder="Enter end date"
                disabledDate={disabledEndDate}
                format="MMM DD, YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {suffixFields}
      {isCreate && (
        <Row gutter={24}>
          <Col span={colSpan}>
            <Form.Item
              label="Inspection Needed"
              name="is_inspection_needed"
              valuePropName="checked" // Ensures that the value is handled as a boolean
              initialValue={false} // Default value is false
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
}
